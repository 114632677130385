@import "./assets/scss/global";
@import "./assets/scss/theme";
@import "@findex/fx-ui/styles/scss/index";
@import "@findex/notifications-angular/assets/styles/index";
@import "@angular/cdk/overlay-prebuilt.css";

$ionicons-font-path: "~ionicons/dist/fonts";
@import "~ionicons/dist/scss/ionicons.scss";

.fx-btn {
    &--secondary,
    &--primary {
        padding-left: 1em;
        padding-right: 1em;
    }
}

.fx-chip {
    background-color: $brand-secondary;
}

.mat-tooltip {
    background-color: $brand-secondary;
    opacity: 0.9;
}

.shepherd {
    &-content {
        color: $shepard-text-color;
        background-color: $shepard-background;
    }
    &-button {
        &:only-child {
            width: 70%;
            margin-left: 15% !important;
            margin-right: 15% !important;
        }

        &:hover {
            //Override shepherdjs' default style
            background-color: $brand-primary !important;
        }
    }
    &-footer {
        padding: 0 0.75rem 36px;
    }
    &-text {
        overflow: hidden;
        max-height: 60vh;
    }
}

.tour {
    &-header {
        margin: 24px 24px 28px 24px;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            padding-left: 8px;
            height: 24px;
        }
    }
    &-content {
        color: $shepard-text-color;

        p {
            padding: 0 18px 12px 18px;
        }
    }
    &-body {
        text-align: center;
    }
    &-logo {
        content: url(#{$brand-image});
        &-align-center {
            margin-bottom: -6px;
        }
    }
    &-tagline {
        height: 56px;
        margin: 16px;
    }
}

.transparent-overlay-background {
    background-color: transparent;
}

* {
    $scroll-bg: #fff;
    $thumb-bg: #e5e5e5;
    scrollbar-color: $thumb-bg $scroll-bg;

    &::-webkit-scrollbar {
        width: 16px;
    }

    &::-webkit-scrollbar-track {
        background: $scroll-bg;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $thumb-bg;
        border-radius: 16px;
        border: 4px solid $scroll-bg;
    }
}

// Note: layout skeleton needs to be refactored to get rid off 100vh hacks.
.has-banner {
    .fx-l {
        > .fx-l-nav {
            padding-top: calc($header-height-mobile + $banner-height-mobile);

            @include breakpoint("tablet") {
                padding-top: calc($header-height-mobile + $banner-height);
            }

            @include breakpoint("desktop") {
                padding-top: $header-height + $banner-height;
            }
        }
        &-canvas {
            > .fx-l-main {
                padding-top: calc($header-height-mobile + $banner-height-mobile);

                @include breakpoint("tablet") {
                    padding-top: calc($header-height-mobile + $banner-height);
                }

                @include breakpoint("desktop") {
                    padding-top: $header-height + $banner-height;
                }
            }
        }
    }

    .fx-threads {
        fx-layout-column {
            height: calc(100vh - ($header-height-mobile + $banner-height-mobile));

            @include breakpoint("tablet") {
                height: calc(100vh - ($header-height-mobile + $banner-height));
            }

            @include breakpoint("desktop") {
                height: calc(100vh - ($header-height + $banner-height));
            }
        }
    }
}
