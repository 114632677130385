dashboard-threads {
    view-extension {
        .threads-task {
            border: 1px solid #ccd6dc;
        }

        &:first-child {
            .threads-task {
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }
        }

        &:last-child {
            .threads-task {
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }
}
