// add variables here. add theme specific classes to _classes.scss

@import "findex-palette.scss";
$navy: #004b78;
$ocean: #00adf0;

$font-family-body: "Lato", sans-serif;
$font-family-heading: "Lato", sans-serif;
$brand-dark-button-type: "primary";
$brand-dark-accent: $brand-primary;
$brand-thread-card-body: $brand-tertiary;
$brand-image: "/assets/images/my-findex-dark.png";
$brand-image-width: 200px;
$menu-brand-image: "/assets/images/my-findex-light.png";
$menu-brand-image-width: 135px;
$secondary-brand-image: "/assets/images/my-findex-crowe-logo.svg";
$secondary-brand-width: 114px;
$secondary-brand-image-logo-only: "/assets/images/my-findex-crowe-logo-icon-only.svg";
$secondary-brand-logo-only-width: 31px;
$header-height: 72px;
$header-height-mobile: 57px;
$banner-height: 72px;
$banner-height-mobile: 114px;
$header-with-banner: $header-height + $banner-height;
$threads-bg: #f8f6f8;
$brand-content-bg: #edeced;
$card-border-color: #bac3c7;
$form-dark-button-outlined: true;
$form-dark-button-type: "primary";
$header-bgColor: $brand-bg-solid;
$shepard-text-color: $brand-tertiary;
$shepard-background: $body-color;
$card-border-line-width: 8px;

$onboarding-bg-signup: "/assets/images/portal-people-shaking-hands.svg";
$onboarding-bg-create-password: "/assets/images/portal-people-shaking-hands.svg";
$onboarding-bg-login: "/assets/images/portal-people-shaking-hands.svg";
$onboarding-bg-verify-mobile: "/assets/images/portal-verify-mobile.svg";
$onboarding-bg-verify-email: "";

$system-avatar: "/assets/images/myfindex-system-avatar.svg";
$system-avatar-size: 18px;

$error-bg: "assets/images/errors/portal-error-something-went-wrong.svg";

$notification-bell-colour: $brand-primary;

$table-border: 1px solid $disabled-color;
$table-border-radius: 5px;

$threads-task-text: $navy;
$threads-task-icon: $ocean;
