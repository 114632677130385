/* Generated by Glyphter (http://www.glyphter.com) on  Tue Apr 26 2022*/
@font-face {
    font-family: 'portal icons';
    src: url('./fonts/portal-icons.eot');
    src: url('./fonts/portal-icons.eot?#iefix') format('embedded-opentype'),
         url('./fonts/portal-icons.woff') format('woff'),
         url('./fonts/portal-icons.ttf') format('truetype'),
         url('./fonts/portal-icons.svg#portal-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='portal-icons-'], [class*='custom-icon-'] {
    line-height: 0;
    margin-top: 0.2em;
}
[class*='portal-icons-']:before{
	display: inline-block;
   font-family: 'portal icons';
   font-style: normal;
   font-weight: normal;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}
[class*='custom-icon-']:before{
	display: inline-block;
   font-family: 'portal icons';
   font-style: normal;
   font-weight: normal;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}
.portal-icons-virtual-background:before{content:'\0041';}
.custom-icon-minimise:before{content:'\0042';}
.custom-icon-maximise:before{content:'\0043';}