@use "sass:math";

@import "src/assets/scss/theme";
@import "src/assets/scss/mixins/error-message.scss";
@import "src/assets/scss/mixins/info-message.scss";

.fx-onboarding {
    $this: &;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    background: $white;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 90%;
    &-container {
        display: flex;
        padding: $gutter-size;
        justify-content: center;
        height: 100%;
        overflow-y: auto;
    }
    &-header {
        @include container();
        .fx-brand {
            margin: $gutter-size ($gutter-size * 0.5) 0 0;
        }
        &--back {
            .fx-brand {
                margin-left: $gutter-size * 2;
            }
        }
    }
    &-content,
    &-content-wide {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
    }
    &-brand {
        width: $brand-image-width;
        margin-bottom: $gutter-size;
    }
    &-title {
        font-size: px-to-rem(24px);
        font-weight: 400;
        margin-top: 0;
    }
    &-body {
        font-size: px-to-rem(16px);
        font-weight: $font-weight-normal;
    }
    &-back,
    &-go-back {
        font-size: px-to-rem(20px);
        &-icon {
            color: $brand-primary;
            cursor: pointer;
        }
    }
    &-back {
        padding: $gutter-size;
        padding-bottom: 0;
    }
    &-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
    }
    &-brand {
        width: $brand-image-width;
        margin-bottom: $gutter-size;
    }
    &-title {
        font-size: px-to-rem(24px);
        font-weight: 400;
        margin-top: 0;
    }
    &-body {
        font-size: px-to-rem(16px);
        font-weight: $font-weight-normal;
        margin-bottom: px-to-rem(32px);
    }
    &-dialog {
        height: 100%;
        &-content {
            height: 100%;
            width: 100%;
        }
    }
    &-button {
        flex: 1 1 100%;
        & + & {
            margin-top: $gutter-size;
        }
    }
    &-heading {
        margin-bottom: $gutter-size;
        font-family: $font-family-heading;
        font-weight: bold;
        font-size: px-to-rem(36px);
        line-height: px-to-rem(40px);
        color: $white;
    }
    &-footer {
        margin-bottom: $gutter-size;
        font-size: px-to-rem(12px);
    }
    &-form-info {
        @include infoMessage;
    }
    &-form-error {
        @include errorMessage;
    }
    &-actions {
        display: block;
    }
    &-action {
        margin-left: math.div($gutter-size, 3);
    }

    // TODO rethink naming of following two blocks, or should it be incorporated into fx-ui
    &-intl-input {
        display: block;
        .iti__country-list {
            overflow: auto;
        }
    }
    .no-flag {
        .iti .iti__flag-container {
            display: none;
        }
    }

    // TODO move in FX-UI
    .fx-form {
        max-width: 270px;
        &-group {
            &-action {
                font-size: px-to-rem(16px);
                text-align: unset;
                color: $brand-primary;
            }
            input:-webkit-autofill {
                border-top: 0;
                border-left: 0;
                border-right: 0;
                box-shadow: inset 0 0 0 100px $form-dark-bgColor;
            }
            &:last-child {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }
        }
        &-disclaimer {
            display: flex;
            align-items: center;

            margin-top: 0;
            margin-bottom: $gutter-size;

            text-align: unset;
        }
    }
    .fx-checkbox {
        &-content {
            margin-left: $gutter-size*0.5;
        }
    }
}

.fx-onboarding-modal {
    display: flex;
    height: 100%;
    flex-direction: column;
    &-image {
        display: none;
    }
    &-title {
        font-size: px-to-rem(32px);
        font-weight: 400;
        margin-top: 0;
        color: $body-color;
    }
    &-dialog {
        width: 100vw;
        height: 100%;
        mat-dialog-container {
            color: $body-color;
            border-radius: 0;
        }
    }
    &-back {
        color: $brand-primary;
        font-size: px-to-rem(26px);
        cursor: pointer;
        width: 26px;
    }
}

@include breakpoint("tablet") {
    .fx-onboarding {
        background-size: 1440px;
        &-container {
            padding: $gutter-size $gutter-size * 4;
        }
    }
    .fx-onboarding-modal {
        flex-direction: initial;

        padding: 0 $gutter-size;

        &-dialog {
            width: 892px;
            height: auto;
            mat-dialog-container {
                border-radius: 4px;
            }
        }
        &-image {
            display: flex;
            justify-content: center;
            width: 100%;
            padding-right: $gutter-size * 2;
        }
        &-back {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

@include breakpoint("laptop") {
    .fx-onboarding {
        justify-content: unset;
        background-size: 1024px;
        &-back {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
        }
        &-container {
            justify-content: flex-start;
        }
        &-content {
            max-width: 320px;
        }
        &-brand {
            margin-bottom: $gutter-size * 2;
        }
    }
}

.onboarding-wizard-modal {
    display: flex;
    height: 100%;
    flex-direction: column;
    &-dialog {
        width: 100vw;
        height: 100%;
        mat-dialog-container {
            color: $body-color;
            border-radius: 0;
            padding: unset;
        }
    }
}
@include breakpoint("tablet") {
    .onboarding-wizard-modal {
        flex-direction: initial;
        &-dialog {
            width: 892px;
            height: auto;
            mat-dialog-container {
                border-radius: 4px;
                padding: unset;
            }
        }
    }
    onboarding-wizard {
        .multi-component-layout.multi-component-layout--onboarding {
            overflow-y: auto;
            height: 100%;
        }
    }
}
