// add classes here that are theme specific that need to leverage fx-ui components, like breakpoints.

.fx-onboarding {
    &-bg-login {
        background-position: right bottom;
    }
    &-bg-password,
    &-bg-verify-mobile {
        background-position: center;
    }
}
@include breakpoint("laptop") {
    .fx-onboarding {
        &-bg-signup {
            background-image: url(#{$onboarding-bg-signup});
        }
        &-bg-password {
            background-image: url(#{$onboarding-bg-create-password});
        }
        &-bg-login {
            background-image: url(#{$onboarding-bg-login});
        }
        &-bg-verify-email {
            background-image: url(#{$onboarding-bg-verify-email});
        }
        &-bg-verify-mobile {
            background-image: url(#{$onboarding-bg-verify-mobile});
        }
    }
}
