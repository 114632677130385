/* palette */

$brand-primary: #d43d27; // Findex Orange
$brand-secondary: #1b1f22; // Findex Purple
$brand-primary-gradient-start: #d43f27;
$brand-primary-gradient-end: #ee4823;
$brand-primary-gradient: linear-gradient(
    187.17deg,
    $brand-primary-gradient-start 31.65%,
    $brand-primary-gradient-end 118.01%
);
$brand-tertiary: white; // White
$body-color: $brand-secondary;
$active-step-color: #00adf0;
$disabled-color: #ccd6dc;
$icon-grey: #6b7683;
$brand-bg-solid: $brand-secondary; // Findex Midnight Blue
$cancelled-meeting: #be1010;
$charcoal: #333;
$brand-colors-hex: map-merge(
    (
        "primary": $brand-primary,
        "secondary": $brand-secondary,
        "tertiary": $brand-tertiary,
        "pink": #ed4562,
        "blue": #0eacbd,
        "warning": #ffea2c,
        "info": #68c9d0,
        "success": #8dc63f,
        "danger": #da3636,
        "yellow": #f9c215,
        "drab-green": #6ac288,
        "green": #8dc63f,
        "purple": #516cb3
    ),
    ()
);
