/*
  Cell mixin
  Reusable grid layout for both parent and child cell elements

  Example use cases:

  ** Layout from the parent:

    .container {
        @include cell(
            $areas: 'header header header header' 'main main main sidebar' 'footer footer footer footer',
            $cols: 1fr 1fr 1fr 2fr,
        );
    }

    .header {
        @include cell($area: header);
    }
    .main {
        @include cell($area: main);
    }
    .sidebar {
        @include cell($area: sidebar);
    }
    .footer {
        @include cell($area: footer);
    }

  ** Layout from the child:

    .parent {
      @include cell(
          $cols: 1fr 1fr,
          $rows: 1fr 1fr 1fr,
      );
    }

    .child {
      @include cell(
          $col: 1 / 2,
          $row: 1 / 3,
      );
    }

*/

@mixin cell(
    $areas: null,
    $cols: null,
    $rows: null,
    $area: null,
    $col: null,
    $row: null,
    $gap: null,
    $alignment-v: null,
    $alignment-h: null,
    $inline: null
) {
    // Defines a grid template by referencing the names used in grid-area
    // Example: $areas: 'header header';
    @if ($areas) {
        grid-template-areas: $areas;
    }

    // Defines the columns of the grid
    // Example: $cols: 1fr 1fr;
    @if ($cols) {
        grid-template-columns: $cols;
    }

    // Defines the rows of the grid
    // Example: $rows: 1fr 2fr;
    @if ($rows) {
        grid-template-rows: $rows;
    }

    // Give the cell a name to be referenced by
    // Example: $area: 'header';
    @if ($area) {
        grid-area: $area;
    }

    // Defines the start and end columns for a cell
    // Example: $col: 1 / 2;
    @if ($col) {
        grid-column: $col;
    }

    // Defines the start and end rows for a cell
    // Example: $row: 1 / 2;
    @if ($row) {
        grid-row: $row;
    }

    // Defines the row and column gap
    // Example: $gap: 1rem 2rem;
    @if ($gap) {
        gap: $gap;
    }

    // Aligns grid items along the column axis (vertically)
    // Example: $alignment-v: center;
    @if ($alignment-v) {
        align-items: $alignment-v;
    }

    // Aligns grid items along the row axis (horizontally)
    // Example: $alignment-h: center;
    @if ($alignment-h) {
        justify-content: $alignment-h;
    }

    // Set the cell to use block level (grid) or inline-level (inline-grid)
    @if ($inline == true) {
        display: inline-grid;
    } @else {
        display: grid;
    }
}
