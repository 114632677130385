/* theme variable defaults */

@use "sass:math";

$body-bg: #fafafa !default;
$threads-bg: unset !default;
$threads-message-bg: $threads-bg !default;
$highlight-bg: #f3f5f6 !default;
$dashboard-greeting-background: rgba(0, 173, 240, 0.05);

$notification-bell-colour: #007fb0 !default;

$onboarding-bg-image: unset !default;
$brand-image-width: unset !default;
$menu-brand-image-width: unset !default;

$brand-horizontal-rule: lighten(#9e91a2, 15) !default;
$brand-horizontal-rule-secondary: #c6d4d6 !default;
$brand-border-radius: 6px !default;

$shepard-text-color: white !default;
$shepard-background: black !default;

$font-family-icons: "Line Awesome Free";
$font-family-icons-path: "~line-awesome/dist/line-awesome/css/line-awesome.min.css";
$font-weight-normal: normal;

$brand-success-green: #4c8400 !default;
$brand-progress-blue: #00adf0 !default;
$brand-progress-red: #d70000 !default;

$loader-bg: rgba(0, 0, 0, 0.3);

$onboarding-progress-bar-height-mobile: 5px;
$onboarding-progress-bar-height-desktop: 10px;

$onboarding-modal-desktop-height: 590px !default;
$onboarding-modal-controls-height: 90px !default;

// onboarding colorful pills
$all-star-pill-color: #fcda5f !default;
$strong-match-pill-color: #03a400 !default;
$good-match-pill-color: #00adf0 !default;

$fullscreen-focus-bg: #e5e5e5;

$banner-bg: rgba(0, 49, 79, 1);
/* theme settings and associated palette import */

// "_theme" is resolved by angular build profile
@import "_theme";

/* variable overrides */

$asset-path: "./";
$root: "@findex/fx-ui/styles/";

.thread {
    .thread-card-loading .card .card-content {
        display: none;
    }
}

.fx-tabs {
    padding: 1.625rem 1.5rem 0 1.5rem;
    background-color: #fff;
    border-bottom: 1px solid $disabled-color;
    &-item {
        text-transform: capitalize !important;
        font-weight: 700;
    }
}

.draggable {
    cursor: move;
    position: absolute;
    z-index: 9;
}

@import "@findex/fx-ui/styles/scss/base/imports";
@import "./components/cell";
@import "_classes";

body {
    font-weight: normal;
}

$sidebar-width: 112px;
$sidebar-tablet-width: 300px;
$sidebar-mobile-width: 100%;

$card-border-line-width: 5px;

$app-container-max-width: 1500px !default;

.app-container {
    max-width: $app-container-max-width;
    margin: 0 auto;
}

@mixin ease-in-out($property) {
    transition: $property 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

@mixin tooltip($content: "") {
    position: absolute;
    top: -100%;

    padding: math.div($gutter-size, 3);
    background-color: white;
    min-width: 112px;

    font-size: 12px;
    color: #1b1f22;
    content: $content;
    text-align: center;
    border-radius: 12px;
    border: 1px solid #f3f5f6;

    opacity: 1;
}
