findex-single-slider {
    width: 100%;
    .ngx-slider {
        .ngx-slider-bar {
            height: 6px;
        }

        &[disabled] {
            cursor: not-allowed;

            .ngx-slider-pointer {
                cursor: not-allowed;
                background-color: $disabled-color;
                &:after {
                    background: $disabled-color;
                }
            }

            .ngx-slider-draggable {
                cursor: not-allowed;
            }

            .ngx-slider-selection {
                background: $disabled-color;
            }

            .ngx-slider-tick {
                cursor: not-allowed;

                &.ngx-slider-selected {
                    background: $disabled-color;
                }
            }
        }

        .ngx-slider-bubble {
            cursor: default;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: white;
            font-size: px-to-rem(16px);
            letter-spacing: 1px;
            z-index: 5;
            position: absolute;
            top: 50px;
            left: -20px;
            background: $brand-secondary;
            border-radius: 5px;
            height: 40px;
            padding: 20px;
        }

        .ngx-slider-pointer {
            background: $brand-primary;
            border: 3px solid white;
            border-radius: 50%;
            top: -16px;
            box-shadow: 2px 2px 5px $icon-grey;

            &.ngx-slider-active {
                &:after {
                    background-color: transparent;
                }
            }

            &:before {
                content: "";
                position: absolute;
                top: 51px;
                left: -1px;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-bottom: 15px solid $brand-secondary;
            }

            &:after {
                content: none;
            }
        }

        .ngx-slider-selection {
            background: $brand-primary;
        }

        .ngx-slider-limit {
            display: none;
        }
    }
}
