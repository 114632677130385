fx-checkbox.todoitem-green-checkbox {
    .fx-checkbox--checked {
        .fx-checkbox-inner-circle,
        .fx-checkbox-outer-circle {
            background-color: $brand-success;
            border: none;
            opacity: 1;

            &:hover:not(:disabled) {
                opacity: 0.7;
            }
        }
    }
}
