fx-context-menu {
    .fx-context-menu-trigger:hover,
    .fx-context-menu-trigger .active {
        color: unset;

        @include breakpoint("laptop") {
            color: $brand-primary;
        }
    }
}
