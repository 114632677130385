// **-- CSA specific hacks --*

@use "sass:math";

.mat-dialog-container {
    .csa-container {
        padding: 0 math.div($gutter-size, 3);
        width: unset;
        max-width: 100vw;
    }

    @include breakpoint("laptop") {
        .csa-container {
            padding: 0 $gutter-size;
            width: 70vw;
        }
    }

    @include breakpoint("desktop") {
        .csa-container {
            width: 50vw;
        }
    }
}

step-layout {
    .iframe-container {
        .csa-container {
            display: flex;
            justify-content: center;

            padding: $gutter-size*0.5;
            width: unset;
        }

        .csa-content {
            width: 90vw;
            padding: $gutter-size;
        }

        @include breakpoint("tablet") {
            .csa-container {
                padding: $gutter-size*0.5;
                width: 80vw;
            }

            .csa-content {
                width: 80vw;
            }
        }

        @include breakpoint("tablet") {
            .csa-content {
                width: 70vw;
                max-width: 1000px;
            }

            .csa-container {
                padding: $gutter-size*0.5;
                width: 70vw;
                max-width: 1000px;
            }
        }
    }
}
