
    //**********************************
    //**********************************
    //**********************************
    //**********************************
    //**********************************
    //TODO: DO NOT MERGE UNTIL DONE
    // A400: #df0074, //TODO: set material theme for date picker to match expected theme
    //**********************************
    //**********************************
    //**********************************
    //**********************************
    //**********************************
    //**********************************
    //**********************************



@use '@angular/material' as mat;
@import "./src/assets/scss/theme";
@import "@angular/material/theming";
@import "./layout/index";
// local components needing to be in global scope for material
@import "./components/modal";
@import "./components/slide-menu";
@import "./components/loader";
@import "./components/cards";
@import "./components/select";
@import "./components/search";
@import "./components/quill";
@import "./components/button";
@import "./components/calendar-card";
@import "./components/date-picker";
@import "./components/avatar";
@import "./components/fx-checkbox";
@import "./components/calendar";
@import "./components/csa";
@import "./components/checkbox";
@import "./components/ngx-slider";
@import "./components/fx-select";
@import "./components/fx-context-menu";
@import "./components/dashboard";
@import "./components/snackbar";
@import "./components/table";
@import "https://fonts.googleapis.com/css?family=Lato:300,400,600,700|Philosopher:400,700&display=swap";

// TODO: fix bug with material overlays
.cdk-global-scrollblock {
    overflow-y: unset !important;
    overflow-x: unset !important;
}

.cdk-overlay-container {
    z-index: 9999 !important;
}

//TODO: add to fx-ui
.fx-accordion-content--is-visible.fx-accordion-content {
    overflow: visible;
}

// FIXME: remove material references
@include mat.core();
$findex-blue: (
    50: #ebf3ff,
    100: #cce1ff,
    200: #9abdef,
    300: #71a1e5,
    400: #5294ef,
    500: #0961dc,
    600: #347ee5,
    700: #3175d3,
    800: #296bc7,
    900: #1c5cb5,
    A100: #80b5ff,
    A200: #529aff,
    A400: #1a79ff,
    A700: #0059d6,
    contrast: (
        50: $brand-secondary,
        100: $brand-secondary,
        200: $brand-secondary,
        300: $brand-secondary,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: white,
        A200: white,
        A400: white,
        A700: white
    )
);
$custom-theme-primary: mat.define-palette($findex-blue);
$custom-theme-accent: mat.define-palette($findex-blue, A400);
$custom-theme-warn: mat.define-palette($findex-blue);
$blue-theme-foreground: (
    base: #131313,
    divider: $dark-dividers,
    dividers: $dark-dividers,
    disabled: $dark-disabled-text,
    disabled-button: rgba(black, 0.26),
    disabled-text: $dark-disabled-text,
    hint-text: $dark-disabled-text,
    secondary-text: #8b9ba3,
    icon: rgba(black, 0.54),
    icons: rgba(black, 0.54),
    text: $body-color,
    slider-min: rgba(black, 0.87),
    slider-off: rgba(black, 0.26),
    slider-off-active: rgba(black, 0.38)
);
@function blue-light-theme($primary, $accent, $warn: mat.define-palette($findex-blue)) {
    @return (
        primary: $primary,
        accent: $accent,
        warn: $warn,
        is-dark: false,
        foreground: $blue-theme-foreground,
        background: mat.$light-theme-background-palette
    );
}

$blue-theme: blue-light-theme($custom-theme-primary, $custom-theme-accent);
@include mat.all-component-themes($blue-theme);

.fx-form-icon + input {
    padding-left: 1.5em;
}

number-of-employees {
    onboarding-tile-selection.override-padding {
        div.onboarding-tile-selection {
            padding: 0 $gutter-size * 0.5;
            @include breakpoint("tablet") {
                padding: 0 $gutter-size * 2;
            }
        }
    }
}
