// TODO: Remove once ED-757 is complete
.fx-select-box {
    position: relative;
    width: 100%;
    max-width: 168px;
    select {
        appearance: none;
        display: block;
        width: 100%;
        height: 32px;
        padding: 0px 24px;
        border-radius: 25px;
        border: 1px solid $brand-primary;
        font-size: px-to-rem(12px);
        color: $brand-primary;
        text-transform: uppercase;
        outline: none;
        background-image: none;
        background-color: $white;
        white-space: nowrap;
        text-overflow: ellipsis;
        option {
            color: $body-color;
        }
    }
    &:after {
        content: "\f107";
        font-family: "Line Awesome Free";
        font-weight: 900;
        color: $brand-primary;
        top: 0;
        right: 13px;
        position: absolute;
        pointer-events: none;
        line-height: 32px;
    }
}

.fx-form-select.fx-form-select--fixed-height {
    .fx-accordion-content {
        max-height: 250px;
        overflow-y: scroll;
    }
}
