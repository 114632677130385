@import "src/assets/scss/theme";

$controls-height: 90px;
$header-height: 62px;

calendar-book-meeting,
calendar-meeting-request,
app-calendar-modal {
    .multi-component-layout.multi-component-layout--book-meeting {
        padding: $gutter-size;
        max-height: calc(100vh - #{$controls-height} - #{$header-height});
    }
}
