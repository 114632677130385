.fx-search {
    width: 100%;
    .fx-form {
        &-icon {
            i {
                color: $brand-secondary;
                transform: rotateY(180deg);
            }
            left: 15px;
        }

        &-input {
            padding: $gutter-size * 0.5 $gutter-size * 0.5 $gutter-size * 0.5 $gutter-size * 2;
            border-radius: 25px;
            border: none;
            background: rgba(186, 195, 199, 0.2);
            &:focus,
            &:hover {
                border-bottom: none;
            }
        }
    }
}
