loader-icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
}

loader-icon.mini-loader {
    position: relative;
    height: 100px;
    transform: scale(0.4);
}
