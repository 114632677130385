app-calendar.fx-calendar--two-columns {
    @include breakpoint("tablet") {
        display: flex;
        width: 100%;
        height: 100%;
        fx-date-picker {
            flex: 0 0 50%;
        }
        fx-time-picker {
            flex: 0 0 50%;
        }
    }
}

app-calendar.fx-calendar--desktop-no-collapse {
    .fx-calendar-date--collapsed {
        height: 40px;
        margin-top: 1rem;
    }

    .fx-calendar-toggle {
        display: none;
    }
}

app-calendar {
    .fx-calendar {
        padding: unset;
        background-color: unset;
    }
    .fx-calendar-month {
        font-size: px-to-rem(18px);
    }
}

fx-time-picker {
    .fx-time-picker {
        &-control-group {
            justify-content: space-evenly;
        }

        &-label {
            border-radius: 4px;
            text-align: center;
            border: 1px solid $card-border-color;
        }

        &--buttons {
            .fx-time-picker {
                &-control-group {
                    .fx-time-picker {
                        &-control {
                            border: unset;
                        }
                    }
                }
            }
        }
    }
}

fx-calendar {
    .fx-calendar-month {
        font-size: px-to-rem(16px);
    }
    // On mobile view - overflow: hidden strectches the height of the date boxes which cause the circle to look less round. The below height and line-height are used to offset the effect.
    .fx-calendar-date-btn {
        height: 38px;
        line-height: 36px;

        @include breakpoint("laptop") {
            height: 40px;
        }
    }
}

@include breakpoint("tablet") {
    fx-time-picker {
        .fx-time-picker {
            &-control-group {
                justify-content: unset;
            }
        }
    }
}
