.slide-menu {
    height: calc(100vh - #{$header-height});
    overflow-y: auto;
    position: relative;
    font-weight: normal;
    &-drawer {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-content: space-between;
    }
    &-avatar {
        outline: none;
        padding: $gutter-size;
        font-weight: normal;
        display: flex;
        align-items: center;
        width: 100%;
        color: $brand-primary;
        cursor: pointer;
        &-image {
            margin-right: $gutter-size*0.5;
            position: relative;
            cursor: pointer;
            outline: 0;
        }
        span {
            display: block;
            font-size: px-to-rem(16px);
            font-weight: $font-weight-normal;
        }
        &-title {
            color: $body-color;
            font-weight: 400;
        }
        &-subtitle {
            color: $body-color;
            font-weight: $font-weight-normal;
            font-size: px-to-rem(16px);
        }
    }
    &-navigation {
        display: flex;
        align-content: space-between;
        flex-wrap: wrap;
        flex: 0 0 100%;
    }
    &-links {
        flex: 0 0 100%;
        padding: ($gutter-size * 0.5) 0;

        &--upper {
            border: none;
        }
        li {
            list-style: none;
            position: relative;
            a {
                color: $body-color;
                font-weight: $font-weight-normal;
                font-size: px-to-rem(16px);
                text-transform: uppercase;
                cursor: pointer;
                padding: 0 $gutter-size;
                line-height: 38px;
                i {
                    margin-right: $gutter-size * 1.3;
                    color: $body-color;
                }
                &.active {
                    color: $brand-primary;
                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 6px;
                        height: 38px;
                        background-color: $brand-primary;
                    }
                    i {
                        color: $brand-primary;
                    }
                }
                span {
                    display: block;
                    width: 100%;
                    text-transform: unset;
                }
            }
            &:last-child {
                margin: 0;
            }
            ul {
                padding-bottom: $gutter-size;
                li {
                    a {
                        padding-left: 4.8rem;
                        text-transform: initial;
                        line-height: px-to-rem(30px);
                        display: block;
                        &.active {
                            font-weight: bold;
                            background-color: $brand-primary;
                            color: $white;
                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    &-subscription {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 $gutter-size;
        line-height: px-to-rem(16px);
        text-transform: unset;
        font-size: px-to-rem(12px);
        color: $body-color;
        &-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-grow: 1;
        }
        &-anchor {
            text-transform: uppercase;
            color: $brand-primary;
            line-height: px-to-rem(16px);
        }
    }
    &-close {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 30px;
        width: 60px;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}
