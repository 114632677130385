// mobile borders
.documents {
    fx-table {
        td.mat-cell {
            &:first-of-type {
                border-top: $table-border;
                border-left: $table-border;
                border-bottom: $table-border;
                border-top-left-radius: $table-border-radius;
                border-bottom-left-radius: $table-border-radius;
                padding-top: 0;
            }
            &:last-of-type {
                border-top: $table-border;
                border-right: $table-border;
                border-bottom: $table-border;
                border-top-right-radius: $table-border-radius;
                border-bottom-right-radius: $table-border-radius;
            }

            &:not(.mobile):first-of-type + .mobile {
                border-top: $table-border;
                padding-top: $gutter-size * 0.5;
            }
            &.mobile:nth-last-child(2) {
                border-bottom: $table-border;
            }
        }
    }
}

.requests {
    fx-table {
        td.mat-cell {
            border-left: $table-border;
            border-right: $table-border;
            &:first-of-type {
                border-top: $table-border;
                border-top-left-radius: $table-border-radius;
                border-top-right-radius: $table-border-radius;
                padding-top: $gutter-size * 0.5;
            }
            &:last-of-type {
                border-bottom: $table-border;
                border-bottom-left-radius: $table-border-radius;
                border-bottom-right-radius: $table-border-radius;
            }
        }
    }
}

.threads {
    fx-table {
        table.mat-table {
            border-spacing: 0;
            td.mat-cell {
                border-left: $table-border;
                border-right: $table-border;
                &:first-of-type {
                    border-top: $table-border;
                    border-top-left-radius: $table-border-radius;
                    border-top-right-radius: $table-border-radius;
                    padding-top: $gutter-size * 0.5;
                }
                &:last-of-type {
                    border-bottom: $table-border;
                    border-bottom-left-radius: unset;
                    border-bottom-right-radius: $table-border-radius;
                }
            }
            tr.mat-row--has-child {
                td.mat-cell {
                    border-bottom: unset;
                }

                td.mat-cell:first-of-type {
                    border-top: $table-border;
                    border-top-left-radius: $table-border-radius;
                    border-top-right-radius: $table-border-radius;
                    padding-top: $gutter-size * 0.5;
                }
                td.mat-cell:last-of-type {
                    border-bottom-right-radius: unset;
                    border-bottom-left-radius: unset;
                }
            }

            tr.row-child {
                td.mat-cell {
                    border-top: unset;
                }

                td.mat-cell:first-of-type {
                    border-top-left-radius: unset;
                    border-top-right-radius: unset;
                    border-bottom-left-radius: $table-border-radius;
                    border-bottom-right-radius: $table-border-radius;
                }
            }
        }
    }
}

@include breakpoint("laptop") {
    .requests {
        fx-table {
            td.mat-cell {
                border-left: unset;
                border-right: unset;
                &:first-of-type {
                    border-left: $table-border;
                    border-bottom: $table-border;
                    border-bottom-left-radius: $table-border-radius;
                }
                &:last-of-type {
                    border-top: $table-border;
                    border-right: $table-border;
                    border-top-right-radius: $table-border-radius;
                }
            }
        }
    }

    .threads {
        fx-table {
            table.mat-table {
                td.mat-cell {
                    border-left: unset;
                    border-right: unset;
                    border-radius: unset;

                    &:first-of-type {
                        border-left: $table-border;
                        border-top: $table-border;
                        border-bottom: $table-border;
                        border-top-left-radius: $table-border-radius;
                        border-bottom-left-radius: $table-border-radius;
                        border-top-right-radius: unset;
                    }
                    &:last-of-type {
                        border-right: $table-border;
                        border-top: $table-border;
                        border-bottom: $table-border;
                        border-top-right-radius: $table-border-radius;
                        border-bottom-right-radius: $table-border-radius;
                    }
                }
                tr.mat-row--has-child {
                    td.mat-cell {
                        border-left: unset;
                        border-right: unset;
                        border-bottom: unset;
                    }

                    td.mat-cell:first-of-type {
                        border-left: $table-border;
                        border-bottom-left-radius: unset;
                        border-top-right-radius: unset;
                    }
                    td.mat-cell:last-of-type {
                        border-right: $table-border;
                        border-bottom: unset;
                        border-bottom-right-radius: unset;
                        border-top-left-radius: unset;
                    }
                }

                tr.row-child {
                    td.mat-cell {
                        border-left: $table-border;
                        border-right: $table-border;
                        border-top: unset;
                    }

                    td.mat-cell:first-of-type {
                        border-top: unset;
                        border-top-left-radius: unset;
                    }
                    td.mat-cell:last-of-type {
                        border-top-right-radius: unset;
                    }
                }
            }
        }
    }
}
