@import "./src/assets/scss/theme";

.fx-btn.fx-btn--small {
    line-height: 16px;
    font-size: 12px;
    height: 20px;
}

.fx-btn.fx-btn--medium {
    line-height: 24px;
    font-size: 12px;
    height: 28px;
}