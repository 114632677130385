@use "sass:math";

@import "./src/assets/scss/theme";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
quill-editor,
quill-view {
    width: 100%;
    .ql-container {
        box-sizing: border-box;
        font-family: $font-family-body;
        font-size: 1rem;
        height: 100%;
        margin: 0px;
        position: relative;
    }
    .ql-container.ql-snow {
        border: unset;
        overflow: auto;
        height: 100%;
        max-height: 120px;
    }
    .ql-container {
        box-sizing: border-box;
        font-family: $font-family-body;
        font-size: 1rem;
        height: 100%;
        margin: 0px;
        position: relative;
    }
    .ql-editor {
        box-sizing: border-box;
        font-size: px-to-rem(16px);
        line-height: 1.42;
        height: 100%;
        outline: none;
        overflow-y: auto;
        overflow-wrap: anywhere;
        padding: unset;
        tab-size: 4;
        text-align: left;
        white-space: pre-wrap;
        word-break: break-word;
    }
    .ql-toolbar.ql-snow {
        border: unset;
        box-sizing: border-box;
        font-family: $font-family-body;
        padding: unset;
        padding-right: $gutter-size*0.25;
        padding-bottom: math.div($gutter-size, 3);
        padding-left: unset;
        position: relative;
        right: 8px;
    }
    .ql-toolbar.ql-snow .ql-formats {
        margin-right: unset;
    }
    .ql-snow .ql-editor a {
        text-decoration: none;
        color: $brand-primary;
    }
}
.reply-details-message {
    quill-view {
        .ql-container.ql-snow {
            border: unset;
            overflow: hidden;
        }
        .ql-editor {
            padding-bottom: unset;
        }
    }
}
.card-description {
    quill-view {
        .ql-container.ql-snow {
            border: unset;
            overflow: hidden;
            max-height: 114px; // height of 6 lines at 19px per line
        }
    }
}

quill-editor {
    .ql-editor.ql-blank::before {
        left: 0;
        font-style: normal;
    }
}

quill-editor.editor-toolbar {
    &--hide-toolbar {
        .ql-toolbar.ql-snow {
            display: none;
        }
    }
    &--show-toolbar {
        .ql-toolbar.ql-snow {
            border: unset;
            box-sizing: border-box;
            font-family: $font-family-body;
            padding: unset;
            padding-right: $gutter-size*0.25;
            padding-bottom: math.div($gutter-size, 3);
            padding-left: unset;
            position: relative;
            right: 8px;
        }
    }
}
